export default {
  state: {
    showReceipt: false,
  },
  mutations: {
    toggleShowReceipt(state, payload) {
      state.showReceipt = payload;
    },
  },
  getters: {
      showTaxInReceipt: () => {
        return localStorage.getItem("showTax");
      },
  }
};
