export default {
    state: {
        order: {
            customer: null,
            discount: {
                type: "percentage",
                value: 0,
                amount: 0,
                percentage: 0,
            },
            items: [],
            payment_method: null,
            bank: null,
            total: {
                excltax: null,
                incltax: null,
                items: null,
                discount: null,
                tax: null,
            },
            receiptId: null,
            reference: null,
            created: null,
        },
        activeTab: 2,
        // TODO Delete this tow line
        orginAmountValue: "",
        disableChangeAmount: false,
    },

    mutations: {
        updateOrginAmountValue(state, payload) {
            state.orginAmountValue = payload;
        },
        updateDisableChangeAmount(state, payload) {
            state.disableChangeAmount = payload;
        },
        updateOrderItemDiscount(state, payload) {
            state.order.items = payload;
        },
        updateOrder(state, payload) {
            state.order = payload;
        },
        addItemToCart(state, payload) {
            const existingCartItem = _.find(
                state.order.items,
                (item) => item.id === payload.id
            );
            if (existingCartItem) {
                state.order.items = state.order.items.map((item) => {
                    return item.id === payload.id ? {...item, quantity: item.quantity + 1 } :
                        item;
                });
            } else {
                if (state.order.hasOwnProperty("items")) {
                    state.order.items.push(payload);
                } else {
                    state.order = { items: [payload] };
                }
            }
            var audioFile = new Audio(require("@/assets/pip.mp3"));
            audioFile.play();
        },

        updateCartItemQuantity(state, payload) {
            const existingCartItem = _.find(
                state.order.items,
                (item) => item.id === payload.id
            );
            if (existingCartItem) {
                state.order.items = state.order.items.map((item) => {
                    return item.id === payload.id ? {...item, quantity: payload.quantity } :
                        item;
                });
            }
        },

        removeItemFromCart(state, payload) {
            const existingCartItem = _.find(
                state.order.items,
                (item) => item.id === payload
            );
            if (existingCartItem.quantity === 1) {
                state.order.items = _.filter(
                    state.order.items,
                    (item) => item.id !== payload
                );
            } else {
                state.order.items = state.order.items.map((item) => {
                    return item.id === payload ? {...item, quantity: item.quantity - 1 } :
                        item;
                });
            }
        },

        removeAllItemFromCart(state, payload) {
            state.order.items = _.filter(
                state.order.items,
                (item) => item.id !== payload
            );
        },

        setCart(state) {
            let originalOrder = {
                customer: null,
                discount: {
                    type: "percentage",
                    value: 0,
                    amount: 0,
                    percentage: 0,
                },
                items: [],
                payment_method: null,
                bank: null,
                total: {
                    incltax: null,
                    excltax: null,
                    subTotal: null,
                    items: null,
                    tax: null,
                },
                reference: null,
                receiptId: null,
                created: null,
            };
            state.order = originalOrder;
        },
        updateActiveTab(state, payload) {
            state.activeTab = payload;
        }
    },
    getters: {
        getOrder: () => {
            let order = localStorage.getItem("order");
            order = order ? JSON.parse(order) : null;
            return order;
        },
    },
    actions: {
        async addItemToCart({ state, commit }, payload) {
            await commit("addItemToCart", payload);
            localStorage.setItem("order", JSON.stringify(state.order));
        },

        async updateCartItemQuantity({ state, commit }, payload) {
            await commit("updateCartItemQuantity", payload);
            localStorage.setItem("order", JSON.stringify(state.order));
        },

        removeItemFromCart({ state, commit }, payload) {
            commit("removeItemFromCart", payload);
            localStorage.setItem("order", JSON.stringify(state.order));
        },

        removeAllItemFromCart({ state, commit }, payload) {
            commit("removeAllItemFromCart", payload);
            localStorage.setItem("order", JSON.stringify(state.order));
        },

        clearCart({ state, commit }) {
            commit("setCart");
            localStorage.setItem("order", JSON.stringify(state.order));
        },

        updateOrder({ commit, state }, payload) {
            commit("updateOrder", payload);
            localStorage.setItem("order", JSON.stringify(state.order));
        },
    },
};