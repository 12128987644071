import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const AdminLayout = () =>
    import ("@/layouts/AdminLayout");

function AuthIsRequired(to, from, next) {
    store.dispatch("auth/verifyToken");
    if (store.state.auth.token) {
        next();
    } else {
        next({
            name: "Login",
        });
    }
}

function NoAuthRequired(to, from, next) {
    if (store.state.auth.token) {
        next({ name: "Pos" });
    } else {
        next();
    }
}

// https://router.vuejs.org/guide/advanced/lazy-loading.html#grouping-components-in-the-same-chunk

const Pos = () =>
    import ( /*webpackChunkName: "groupedPosCheckout" */ "@/views/pos/Pos.vue");
const Checkout = () =>
    import ( /*webpackChunkName: "groupedPosCheckout" */ "@/views/checkout/Checkout.vue");

const Customer = () =>
    import ("@/views/customer/Customer.vue");

const Receipts = () =>
    import ( /*webpackChunkName: "groupedReceipt" */ "@/views/receipt/Receipts.vue");
const Onhold = () =>
    import ( /*webpackChunkName: "groupedReceipt" */ "@/views/receipt/Onhold.vue");

const Cash = () =>
    import ( /*webpackChunkName: "groupedCash" */ "@/views/cashier/Index.vue");
const CashChecking = () =>
    import (
        /*webpackChunkName: "groupedCash" */
        "@/views/cashier/CashChecking.vue"
    );

const Statistics = () =>
    import ("@/views/statistics/Index.vue");
const Settings = () =>
    import ("@/views/settings/Settings");
const Login = () =>
    import ("@/views/auth/Login.vue");
const PageNotFound = () =>
    import ("@/views/Error404.vue");

const routes = [{
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: NoAuthRequired,
    },
    {
        path: "/",
        component: AdminLayout,
        children: [{
                path: "",
                name: "Pos",
                component: Pos,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "checkout",
                name: "Checkout",
                component: Checkout,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "receipts",
                name: "Receipts",
                component: Receipts,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "onhold",
                name: "Onhold",
                component: Onhold,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "customers",
                name: "Customer",
                component: Customer,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "cashier",
                name: "Cashier",
                component: Cash,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "cashcheck",
                name: "CashChecking",
                component: CashChecking,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "statistics",
                name: "Statistics",
                component: Statistics,
                beforeEnter: AuthIsRequired,
            },
            {
                path: "settings",
                name: "Settings",
                component: Settings,
                beforeEnter: AuthIsRequired,
            },
        ],
    },
    {
        path: "/:catchAll(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkExactActiveClass: "bg-light",
});

export default router;