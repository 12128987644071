export default {
  install(app) {
    app.config.globalProperties.$filters = {
      numFormat(number) {
        let config = {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        return new Intl.NumberFormat("fr-FR", config).format(number);
      },
    };
  },
};
