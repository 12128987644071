import http from "./http.service";

class CatalogService {

    get() {
        return http.get('/pos/catalog');
    }

    getItems(id) {
        return http.get('/pos/items/' + id);
    }

}

export default CatalogService;