<template>
  <base-spinner v-if="showLoading" />
  <router-view />
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      showLoading: (state) => state.auth.showLoading,
    }),
  },
};
</script>
<style>
html {
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
  max-height: 10px;
  border-top: 60px solid rgb(2, 117, 216);
}


</style>
