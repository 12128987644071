<template>
    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
        </div>
    </div>
</template>

<style scoped>
.spinner-border {
    margin-top:200px
}
</style>