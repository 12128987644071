import vSelect from "vue-select";
import Datepicker from "@vuepic/vue-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
export default {
  install(app) {
    app.component("VeeSelect", vSelect);
    app.component("Datepicker", Datepicker);
    app.component(VueSweetalert2);
  },
};
