export default {
  state: {
    holdOrders: {
      orders: [],
    },
  },
  mutations: {
    addHoldOrder(state, payload) {
      if (state.holdOrders.hasOwnProperty("orders"))
        state.holdOrders.orders.push(payload);
      else state.holdOrders = { orders: [payload] };
    },
  },
  getters: {
    getHoldOrders: () => {
      let holdOrders = localStorage.getItem("holdOrders");
      holdOrders = holdOrders ? JSON.parse(holdOrders) : null;

      return holdOrders;
    },
  },
  actions: {
    addHoldOrder({ getters }, payload) {
      if (getters.getHoldOrders) {
        let updatedHoldOrders = getters.getHoldOrders.push(payload);
        localStorage.setItem("holdOrders", JSON.stringify(updatedHoldOrders));
        return;
      }
      localStorage.setItem("holdOrders", JSON.stringify([payload]));
    },

    removeHoldOrder({ getters }, payload) {
      let updatedHoldOrders = _.filter(getters.getHoldOrders, function (o) {
        return o.date !== payload;
      });

      localStorage.setItem("holdOrders", JSON.stringify(updatedHoldOrders));
    },
  },
};
