import { camelCase } from "lodash";
import { createStore } from "vuex";

const modules = {};
const requireModule = require.context("./modules", false, /\.js$/);

requireModule.keys().forEach((fileName) => {
    let moduleConfig = requireModule(fileName);
    const modulName = camelCase(fileName.replace(/(\.\/|\.js)/g, ""));
    modules[modulName] = moduleConfig.default || moduleConfig;
  });

export default createStore({
    modules,
});