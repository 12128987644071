import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure,
} from "vee-validate";

import {
  required,
  min,
  max,
  alpha_spaces as alphaSpaces,
  min_value as minVal,
  max_value as maxVal,
} from "@vee-validate/rules";

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);
    defineRule("required", required);
    defineRule("price", required);
    defineRule("alpha_spaces", alphaSpaces);
    defineRule("max", max);
    defineRule("min", min);
    defineRule("min_value", minVal);
    defineRule("max_value", maxVal);
    configure({
      generateMessage: (ctx) => {
        const messages = {
          required: `ce champ est obligatoire.`,
          min: `Le champ ${ctx.field} est trop court.`,
          max: `Le champ ${ctx.field} est trop long.`,
          alpha_spaces: `Le champ ${ctx.field} ne peut contenir que des caractères alphabétiques et des espaces`,
          min_value: `Le champ ${ctx.field} est trop bas.`,
          max_value: `La valeur saisie est très élevée`,
        };
        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `Le champ ${ctx.field} est invalide`;
        return message;
      },
      validateOnBlure: true,
      validateOnChange: true,
      validateOnInput: true,
      validateOnModelUpdate: true,
    });
  },
};
