import AuthService from "@/services/auth.service";
const authservice = new AuthService();

export default {
  namespaced: true,
  state() {
    return {
      showLoading: false,
      token: localStorage.getItem("token") || null,
      user: null,
      name: "Authentification",
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload.token;
    },
    clearAuthUser(state) {
      localStorage.clear();
      (state.token = null), (state.user = null);
    },
    loadSpinner(state, payload) {
      state.showLoading = payload;
    },
  },
  getters: {
    getUserToken: (state) => {
      return state.token;
    },
    getAuth: (state) => {
      if (state.token) {
        return true;
      }
      return false;
    },
    currentUser() {
      return localStorage.getItem("userFullName") || null;
    },
  },
  actions: {
    async signIn(context, payload) {
      try {
        const response = await authservice.login(payload);
        if (response.data.status == "error") {
          context.commit("loadSpinner", false);
          let errorMsg = response.data.message;
          return { message: errorMsg, status: "error" };
        }

        let token = response.data.token.replace('JWT ', '');

        localStorage.setItem("token", token);
        context.commit("setToken", {
          token,
        });

        context.commit("setUser", {
          user: response.data.user,
        });

        let { firstname, lastname } = response.data.user;
        let userFullName = `${firstname} ${lastname}`;
        localStorage.setItem("userFullName", userFullName);
        return { data: response, status: "success" };
      } catch (err) {
        if (err.response.status === 500) {
          let errorMsg = "Erreur de connexion avec le serveur";
          return { message: errorMsg, status: "error" };
        }
      }
    },
    async verifyToken({commit}) {
        try {
           await authservice.isTokenValid();
        } catch (error) {
            commit("clearAuthUser");
            window.location.href = "/login";
        }
    },
    logout({ commit }, payload) {
      commit("clearAuthUser");
      payload.router.push({ name: "Login" });
    },
  },
};
