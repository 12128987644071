<template>
  <div class="row justify-content-between">
    <div class="col-6">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item ">{{ name }}</li>
          <li class="breadcrumb-item active" style="color: #0506b9;" aria-current="page">{{ pageName }}</li>
        </ol>
      </nav>
    </div>
    <div class="col-4 text-right text-primary">
      <span class="border border-primary p-2 mt-4 rounded bg-white">
        <i class="fa fa-user"></i
        ><span class="ml-2">{{ currentUser}}</span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["pageName", "storeName"],
  created() {
    this.currentStoreName = this.getApiData.settings.store.title;
  },
  computed: {
    ...mapGetters(["getApiData"]),
    ...mapGetters('auth', {
      currentUser : 'currentUser',
    }),
    name() {
      return this.currentStoreName ? this.currentStoreName : storeName;
    },
  },
};
</script>
