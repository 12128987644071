import http from "./http.service";

class AuthService {

    login(data) {
        return http.post('/user/auth', data);
    }

    getRelatedEmailAcounts(email) {
        return http.post('/auth/user/email-org', email);
    }

    isTokenValid() {
        return http.get('/auth/token');
    }
}

export default AuthService;