export default {
    install(app) {
        let baseComponents = require.context("../components/base/", true, /[A-Za-z0-9-_,\s]+\.vue$/i);
        baseComponents.keys().forEach((fileName) => {
            const componentConfig = baseComponents(fileName);
            const componentName = _.upperFirst(
                _.camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, "")),
            );
            app.component(`Base${componentName}`, componentConfig.default || componentConfig);
        });
    },
};