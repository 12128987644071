import CatalogService from "@/services/catalog.service";
import CustomerSevice from "@/services/customer.service";
import SettingsService from "@/services/settings.service";

const catalogservice = new CatalogService();
const customerservice = new CustomerSevice();
const settingsService = new SettingsService();

export default {
    getters: {
        getApiData: () => {
            let apiData = localStorage.getItem("apiData");

            let localestoreApiData = apiData ? JSON.parse(apiData) : [];

            return typeof localestoreApiData !== "undefind" &&
                localestoreApiData !== null ?
                localestoreApiData : [];
        },
    },
    actions: {
        async fetchDataFromApi() {
            return new Promise(async(resolve) => {
                let categories, customers, items, settings;
                try {
                    const catalog = await catalogservice.get();
                    categories = catalog.data.categories;
                    items = catalog.data.items.map((item) => {
                        return {...item, name: item.name.toLowerCase(), discount: 0 };
                    });
                } catch (error) {}

                try {
                    const response = await customerservice.get();
                    customers = response.data;
                } catch (error) {}

                try {
                    const response = await settingsService.get();
                    settings = response.data;
                } catch (error) {}
                let apiData = {
                    categories,
                    items: items,
                    customers,
                    settings,
                };
                localStorage.setItem("apiData", JSON.stringify(apiData));
                resolve(apiData);
            });
        },

        updateCustomersApiData({ getters }, payload) {
            let storedAPiData = getters.getApiData;
            storedAPiData = {...storedAPiData, customers: payload };
            localStorage.setItem("apiData", JSON.stringify(storedAPiData));
        },

        async updateItemsApiData({ getters }) {
            let items;
            let categories;
            try {
                const catalog = await catalogservice.get();
                categories = catalog.data.categories;
                items = catalog.data.items.map((item) => {
                    return {...item, name: item.name.toLowerCase() };
                });
            } catch (error) {}
            let storedAPiData = {...getters.getApiData, items, categories };
            localStorage.setItem("apiData", JSON.stringify(storedAPiData));
        },

        async getApiData({ getters, dispatch }) {
            return new Promise((resolve) => {
                if (Object.keys(getters.getApiData).length > 0) {
                    resolve(getters.getApiData);
                } else {
                    dispatch("fetchDataFromApi").then((res) => resolve(res));
                }
            });
        },
    },
};