<template>
  <Datepicker
    :enableTimePicker="false"
    :maxDate="getCurrentDate()"
    :partialRange="false"
    format="dd-MM-yyyy"
    locale="fr"
    cancelText="Annuler"
    selectText="OK"
    v-model="date"
    range
    @update:modelValue="$emit('handleDate', date)"
    preventMinMaxNavigation
    :presetRanges="presetRanges"
  />
</template>
<script>
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  endOfDay,
  subMonths,
} from "date-fns";
export default {
  name: "DateFilter",
  props: ["date"],
  data() {
    return {
      presetRanges: [
        { label: "Aujourd'hui", range: [new Date(), new Date()] },
        {
          label: "Ce mois",
          range: [startOfMonth(new Date()), endOfDay(new Date())],
        },
        {
          label: "Le mois dernier",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          label: "Cette année",
          range: [startOfYear(new Date()), endOfDay(new Date())],
        },
      ],
    };
  },
  methods: {
    getCurrentDate() {
      const d = new Date();
      const NoTimeDate =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      return NoTimeDate;
    },
  },
};
</script>
