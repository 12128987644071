import http from "./http.service";

class SettingsService {

    get() {
        return http.get('/pos/settings');
    }

    getCountries() {
        return http.get('/countries');
    }

    update(id, data) {
        return http.post('/pos/store/' + id, data);
    }
}

export default SettingsService;