import axios from "axios";

const url = process.env.VUE_APP_API_URL ;
const http = axios.create();

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  config.baseURL =  url
  return config;
}, error => {
  console.log(error);
});

export default http;
