<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
          <button
            type="button"
            class="btn btn-danger text-white float-right"
            data-dismiss="modal"
          >
          <i class="fas fa-times"></i> Annuler  
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
  methods: {
    showModal() {
      $("#exampleModal").modal("show");
    },
    closeModal() {
      $("#exampleModal").modal("hide");
    },
  },
};
</script>
<style scoped>
.inline {
  width: 25%;
  margin-left: 22rem; 
  margin-top: -6rem;
}
</style>
