<template>
  <div class="form-inline">
    <div class="form-group">
      <input
        class="form-control"
        @keyup="$emit('searchEvent', search)"
        v-model="search"
        :placeholder=" placeholder ? placeholder : 'tapez ici pour rechercher'"
      />
      <button v-show="search" class="btn btn-info ml-2" @click.prevent="reset" v-if="showIcon">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['showIcon', 'placeholder'],

  data() {
    return {
      search: "",
      defaultPlaceholder: ''
    };
  },
  mounted() {
    window.addEventListener('clear-search-input', (e)=>{
      this.search = "";
    })
  },
  methods: {
    reset() {
      this.search = "";
      this.$emit("reset");
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  font-size: 1.7rem;
  border-radius: 2%;
  input {
    height: 3rem;
  }
  span {
    background-color: #4e73df;
    border-color: #4e73df;
    i {
      color: white;
    }
  }
  .form-control:focus {
    box-shadow: none;
  }
}
</style>
