<template>
  <button :class="customClass" @click.prevent="all" v-if="rendered">
    <i class="fas fa-sync" v-if="showIcon"></i>
    Réinitialiser le filtre
  </button>
</template>

<script>
export default {
  name: "RemoveFiletrButton",
  props: {
    customClass: {
      type: String,
      required: true,
    },
    rendered: {
      type: Boolean,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    all: {
      type: Function,
      required: true,
    },
  },
};
</script>
