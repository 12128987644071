import http from "./http.service";

class CustomerService {

    get() {
        return http.get('/pos/customers');
    }

    store(data) {
        return http.post('/account/create', data)
    }
}

export default CustomerService;