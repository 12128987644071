export default {
  namespaced: true,
  state() {
    return {
      cashToEdit: null,
    };
  },
  mutations: {
    setCashToEdit(state, playload) {
      state.cashToEdit = playload;
    },
  },
  getters: {
    getCashToEdit: (state) => {
      return state.cashToEdit;
    },
    getBalances: () => {
      return JSON.parse(localStorage.getItem('cashBalance'));
    },
  },
  actions: {
    setCaisseBalance(context, playload) {
      localStorage.setItem("cashBalance", JSON.stringify(playload));
    }
  },
};
