import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidatePlugin from "@/includes/validation";
import NumFormatPlugin from "@/includes/numFormat";
import GlobalComponentPlugin from "@/includes/globalComponents";
import VueToast from "vue-toast-notification";
import Paginate from "vuejs-paginate-next";
import "./registerServiceWorker";
import "@/assets/main.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "jquery.easing/jquery.easing.min";
import "vue-toast-notification/dist/theme-sugar.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import "vue-select/dist/vue-select.css";
import "vue3-carousel/dist/carousel.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/dist/css/bootstrap.css";
import globalComponentGonfigiration from '@/includes/_globals';
import Tabs from 'vue3-tabs';

window.$ = window.jQuery = require("jquery");
window._ = require("lodash");

const app = createApp(App);
app.use(VueSweetalert2);
app.use(store);
app.use(router);
app.use(VeeValidatePlugin);
app.use(NumFormatPlugin);
app.use(GlobalComponentPlugin);
app.use(globalComponentGonfigiration);
app.use(VueToast);
app.use(Paginate);
app.use(Tabs);
app.mount("#app");